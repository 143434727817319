import React from 'react';
import { NextSeo } from 'next-seo';

import Link from 'components/Link';
import Logo from 'components/Logo';
import Button from 'components/Button';

const NotFoundPage = () => (
  <div className="bg-gray-dark h-full flex justify-center items-center min-h-screen">
    <NextSeo title="Page Not Found" noindex nofollow />
    <div className="container relative">
      <div className="max-w-4xl mx-auto text-center pt-8 pb-24 lg:py-32">
        <Link href="/">
          <Logo className="w-32 mx-auto mb-8 lg:hidden" />
        </Link>
        <h1>
          <span className="block uppercase text-gray text-lg lg:text-2xl leading-none mb-6">
            Error 404
          </span>
          <span className="block font-script text-accent leading-none text-6xl lg:text-9xl">
            Not Found
          </span>
        </h1>

        <div className="mt-12">
          <p className="text-white text-xl leading-relaxed">
            The page you were looking has either been moved or does not exist
          </p>
          <Button href="/" className="mt-8">
            Back to home
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
